<template>
  <v-card class="mb-2" :loading="(loading || save_loading)">
    <v-toolbar dense flat>
      <v-spacer/>
      <v-btn v-if="!profile" :disabled="(loading || save_loading)" :loading="(loading || save_loading)" class="ma-3" small @click="exportPerson('pdf')" color="primary">
        {{ $store.getters.translate("export_to_pdf") }}
        <v-icon right x-small>mdi-download</v-icon>
      </v-btn>
      <v-btn v-if="!profile" :disabled="(loading || save_loading)" :loading="(loading || save_loading)" class="ma-5" small @click="exportPerson('excel')" color="primary">
        {{ $store.getters.translate("export_to_excel") }}
        <v-icon right x-small>mdi-download</v-icon>
      </v-btn>
      <v-btn v-if="canDelete" @click="$emit('archive')" text small color="secondary">
        <v-icon color="error">mdi-archive</v-icon>
      </v-btn>
      <v-btn v-if="canEdit" text small @click="save" color="primary">
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider/>
    <v-row>
      <v-col style="margin: 25px" lg="3" sm="12" md="12" class="d-flex justify-center">
        <v-row>
          <v-col>
            <v-row>
              <v-col>
                <base-profile-photo :person="record.person" @refresh="save"/>
              </v-col>
            </v-row>
            <v-row class="mt-5">
              <v-col>
                <slot name="below_photo"/>
              </v-col>
            </v-row>
            <v-row style="margin: 0px" v-if="module_name === 'portal' || module_name === 'profile'">
              <v-col style="padding: 0px; text-align: center">
                <v-switch style="margin: 0px" v-model="record.notify_is_on" :label="$store.getters.translate('turn_on_notifications')"/>
              </v-col>
            </v-row>
            <v-row style="margin: 0px" v-if="module_name === 'portal' || module_name === 'profile'">
              <v-col style="padding: 0px; text-align: center">
                <v-btn-toggle light v-model="record.language">
                  <v-btn small value="nl"> NL </v-btn>
                  <v-btn small value="en"> EN </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row style="margin: 0px" v-if="module_name === 'portal' || module_name === 'profile'">
              <v-col style="padding: 0px; text-align: center">
                <v-btn class="mt-5" small @click="cleanCache">{{ $store.getters.translate("clean_cache") }} </v-btn><br />
                <small class="mt-2">{{ $store.getters.translate("clean_cache_hint") }}</small>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col style="margin-right: 25px">
        <v-tabs
          v-if="record.person"
          v-model="tab"
          :centered="false"
          :grow="false"
          :vertical="false"
          :right="false"
          :icons-and-text="false">
          <v-tab v-if="name_required">{{ $store.getters.translate("general_info") }}</v-tab>
          <v-tab>{{ $store.getters.translate("contact_information") }}</v-tab>
          <v-tab>{{ $store.getters.translate("additional_information") }}</v-tab>
          <v-tab v-if="user_type === 'candidate' || (user_type === 'contact' && module_name === 'portal')">{{ $store.getters.translate("financial_information") }}</v-tab>
          <v-tab v-if="module_name !== 'portal' && user_type === 'candidate'">{{ $store.getters.translate("orders") }}</v-tab>
          <v-tab-item v-if="name_required">
            <v-card flat tile>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-text-field
                        :class="'ma-0 pt-'+$store.getters.lineheight"
                        v-if="record.id && module_name !== 'portal' && module_name !== 'profile'"
                        :label="$store.getters.translate('id')"
                        :value="user_type + ' ID: ' + record.id"
                        disabled/>
                    <v-text-field
                        :class="'ma-0 pt-'+$store.getters.lineheight"
                        :label="$store.getters.translate('first_name')"
                        v-model="record.person.first_name"
                        validate-on-blur>
                      <template #label>{{ $store.getters.translate('first_name') }}<span class="red--text"><strong>*</strong></span></template>
                    </v-text-field>
                    <v-text-field
                        :class="'ma-0 pt-'+$store.getters.lineheight"
                        :label="$store.getters.translate('insertion')"
                        v-model="record.person.insertion"
                        validate-on-blur/>
                    <v-text-field
                        :class="'ma-0 pt-'+$store.getters.lineheight"
                        :label="$store.getters.translate('last_name')"
                        v-model="record.person.last_name"
                        validate-on-blur>
                      <template #label>{{ $store.getters.translate('last_name') }}<span class="red--text"><strong>*</strong></span></template>
                    </v-text-field>
                    <v-text-field
                        :class="'ma-0 pt-'+$store.getters.lineheight"
                        :label="$store.getters.translate('initials')"
                        v-model="record.person.initials"
                        validate-on-blur/>
                    <v-row v-if="user_type === 'candidate' && module_name !== 'portal' && module_name !== 'profile'">
                      <v-col>
                        <v-switch
                            :class="'ma-0 pt-'+$store.getters.lineheight"
                            v-model="record['is_dyslexic']"
                            :label="$store.getters.translate('is_dyslexic')"
                            dense/>
                      </v-col>
                      <v-divider v-if="!$vuetify.breakpoint.mdAndDown" vertical/>
                      <v-col>
                        <v-switch
                            :class="'ma-0 pt-'+$store.getters.lineheight"
                            v-model="record['is_dyscalc']"
                            :label="$store.getters.translate('is_dyscalc')"
                            dense/>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat tile>
              <v-card-text>
                <v-row>
                  <v-col>
                    <template v-for="field in usual_fields">
                      <template v-if="field.name === 'can_view_certificates' || field.name === 'jobtitle' || field.name === 'reference' || field.name === 'preferred_certificate_language' || field.name === 'type'">
                          <base-field
                              v-if="module_name === 'portal' || module_name === 'profile'"
                              :key="field.name"
                              :value="record.person[user_type][field.name]"
                              :field="field"
                              @change="usualFieldDataChanged"/>
                          <base-field
                              v-else
                              :key="field.name"
                              :value="record[field.name]"
                              :field="field"
                              @change="usualFieldDataChanged"/>
                      </template>
                      <template v-else-if="field.name !== 'is_dyslexic' && field.name !== 'is_dyscalc'">
                        <base-field
                            :key="field.name"
                            :value="record.person[field.name]"
                            :field="field"
                            @change="usualFieldDataChanged"/>
                      </template>
                    </template>
                    <v-text-field
                        v-if="record.person.user"
                        :label="$store.getters.translate('user_email')"
                        v-model="record.person.user.email"/>
                    <email-addresses v-model="record.person.emailaddresses" email_type="personal" label="emailaddress" @change="save"/>
                    <base-addresses v-model="record.person.addresses" @change="save" address_type="visiting" label="contact_address" mapID="map1"/>
                    <template v-if="tags_required">
                      <base-tags v-if="module_name === 'portal' || module_name === 'profile'" v-model="record.person[user_type].tags" :module_name="user_type + 's'"/>
                      <base-tags v-else v-model="record.tags" :module_name="user_type + 's'"/>
                    </template>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat tile>
              <v-card-text>
                <v-row>
                  <v-col>
                    <template v-for="field in custom_fields">
                      <base-field
                          v-if="module_name === 'portal' || module_name === 'profile'"
                          :key="field.name"
                          :value="record.person[user_type].custom_fields[field.name]"
                          :field="field"
                          @change="customFieldDataChanged"/>
                      <base-field
                          v-else
                          :key="field.name"
                          :value="record.custom_fields[field.name]"
                          :field="field"
                          @change="customFieldDataChanged"/>
                    </template>
                    <v-text-field
                        :class="'ma-0 pt-'+$store.getters.lineheight"
                        v-if="user_type === 'candidate' && record.stipelcandidate && record.stipelcandidate.s_candidate_id && module_name !== 'portal' && module_name !== 'profile'"
                        :label="$store.getters.translate('stipel')"
                        :value="'Stipel ID: ' + record.stipelcandidate.s_candidate_id"
                        disabled/>
                    <template v-if="module_name !== 'portal' && module_name !== 'profile'">
                      <base-log-field
                          v-if="user_type === 'candidate'"
                          :model_type="user_type"
                          :model_id="record.id"
                          :related_model_type="'Dossier'"
                          :related_model_id="last_dossier_id"/>
                      <base-log-field v-else :model_type="user_type" :model_id="record.id"/>
                    </template>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="user_type === 'candidate' || (user_type === 'contact' && module_name === 'portal')">
            <v-card flat tile>
              <v-card-text v-if="user_type === 'candidate'">
                <v-row>
                  <v-col>
                    <v-text-field
                        :class="'ma-0 pt-'+$store.getters.lineheight"
                        :label="$store.getters.translate('financial_name')"
                        v-model="record.person.financial_name"
                        validate-on-blur/>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <email-addresses v-model="record.person.emailaddresses" email_type="billing" label="billing_email" @change="save"/>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-switch v-if="user_type === 'candidate'" :label="$store.getters.translate('billing_address_the_same')" v-model="hide_billing_address"/>
                  </v-col>
                </v-row>
                <v-row v-if="!hide_billing_address">
                  <v-col>
                    <base-addresses v-model="record.person.addresses" @change="save" address_type="billing" label="billing_address" mapID="map2"/>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-else-if="user_type === 'contact'">
                <template v-for="(company, index) in record.companies">
                  <v-row :key="index">
                    <v-col>
                      <v-text-field
                          :class="'ma-0 pt-'+$store.getters.lineheight"
                          :label="$store.getters.translate('company')"
                          v-model="company.name"
                          validate-on-blur/>
                    </v-col>
                  </v-row>
                  <v-row :key="index">
                    <v-col>
                      <v-text-field
                          :class="'ma-0 pt-'+$store.getters.lineheight"
                          :label="$store.getters.translate('ascription')"
                          v-model="company.ascription"
                          validate-on-blur/>
                    </v-col>
                  </v-row>
                  <v-row :key="index">
                    <v-col>
                      <base-addresses v-model="company.addresses" @change="save" address_type="visiting" label="contact_address" :mapID="'map' + (index * 2)"/>
                    </v-col>
                  </v-row>
                  <v-row :key="index">
                    <v-col>
                      <v-switch class="mt-0" :label="$store.getters.translate('billing_address_the_same')" v-model="hide_company_billing_address[index]" @change="hideCompanyBillingAddress(index)"/>
                    </v-col>
                  </v-row>
                  <v-row v-if="!hide_company_billing_address[index]" :key="index">
                    <v-col>
                      <base-addresses v-model="company.addresses" @change="save" address_type="billing" label="billing_address" :mapID="'map' + ((index * 2) + 1)"/>
                    </v-col>
                  </v-row>
                  <v-divider class="mt-5 mb-10" :key="index"/>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="module_name !== 'portal' && user_type === 'candidate'">
            <v-card flat tile>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                        <tr>
                          <th style="padding: 5px;">{{ $store.getters.translate('id') }}</th>
                          <th style="padding: 5px;">{{ $store.getters.translate('exam_name') }}</th>
                          <th style="width: 140px;text-align: center;padding: 0;">{{ $store.getters.translate('order_id') }}</th>
                          <th style="width: 100px;padding: 0;">{{ $store.getters.translate('is_payed') }}</th>
                          <th style="width: 140px;text-align: center;padding: 0;">{{ $store.getters.translate('invoice_id') }}</th>
                          <th style="width: 100px;padding: 0;">{{ $store.getters.translate('is_billed') }}</th>
                        </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(exam, index) in record.exam_candidates" :key="index">
                            <template v-if="exam.exam">
                              <td style="padding: 5px;">
                                <a @click="$router.push('/exams/' + exam.exam_id)">
                                  {{ exam.exam_id }}
                                </a>
                              </td>
                              <td style="padding: 5px;">
                                <a @click="$router.push('/exams/' + exam.exam_id)">
                                  {{ exam.exam_name }}
                                </a>
                              </td>
                              <td style="padding: 5px;">
                                <v-text-field
                                    flat
                                    v-model="exam.order_id"
                                    dense
                                    class="centered-input"
                                    placeholder="-"
                                    single-line
                                    @change="updateOrder(exam)"/>
                              </td>
                              <td style="padding: 5px;">
                                <v-switch v-model="exam.is_payed" @change="updateOrder(exam)"/>
                              </td>
                              <td style="padding: 5px;">
                                <v-text-field
                                    flat
                                    v-model="exam.invoice_id"
                                    dense
                                    class="centered-input"
                                    placeholder="-"
                                    single-line
                                    @change="updateOrder(exam)"/>
                              </td>
                              <td style="padding: 5px;">
                                <v-switch v-model="exam.is_billed" @change="updateOrder(exam)"/>
                              </td>
                            </template>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    <slot name="footer"/>
  </v-card>
</template>

<script>
import BaseProfilePhoto from "./BaseProfilePhoto";
import BaseAddresses from "./BaseAddresses";
import BaseLogField from "./BaseLogField";
import helpFunctions from "../../plugins/helpFunctions";
export default {
  name: "BaseProfile",
  props: ["record", "module_name", "user_type", "profile", "save_loading"],
  components: {
    BaseProfilePhoto,
    BaseAddresses,
    BaseLogField,
  },
  data() {
    return {
      tab: 0,
      loading: false,
      last_dossier_id: null,
      usual_fields: [],
      custom_fields: [],
      hide_billing_address: true,
      hide_company_billing_address: [],
      tags_required: false,
      name_required: false,
      countries: [],
      nationalities: [],
      exams_for_export: [],
      record_results: [],
      dossier_counter: 0,
    };
  },
  mounted() {
    this.getCountryNationality();
  },
  methods: {
    save() {
      let error = false;
      if (this.record.person.first_name === '' || this.record.person.last_name === '') {
        this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate('name'));
        error = true;
      }
      this.usual_fields.forEach((field) => {
        if (field.mandatory && field.field_type !== 'boolean') {
          if(field.name === 'can_view_certificates' || field.name === 'jobtitle' || field.name === 'reference' || field.name === 'preferred_certificate_language' || field.name === 'type') {
            if(this.module_name === 'portal' || this.module_name === 'profile') {
              if(!this.record.person[this.user_type][field.name] || this.record.person[this.user_type][field.name] === '') {
                this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate(field.name));
                error = true;
              }
            }
            else {
              if(!this.record[field.name] || this.record[field.name] === '') {
                this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate(field.name));
                error = true;
              }
            }
          }
          else if (field.name !== 'name' && field.name !== 'is_dyslexic' && field.name !== 'is_dyscalc') {
            if(!this.record.person[field.name] || this.record.person[field.name] === '') {
              this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate(field.name));
              error = true;
            }
          }
        }
      });
      if(!error) {
        if(this.record.person.phonenumber && this.record.person.phonenumber.charAt(0) !== '+') {
          this.$toasted.error(this.$store.getters.translate("international_number_error"));
        }
        else if(this.record.person.mobilenumber && this.record.person.mobilenumber.charAt(0) !== '+') {
          this.$toasted.error(this.$store.getters.translate("international_number_error"));
        }
        else {
          this.$emit('change');
        }
      }
    },
    updateOrder(pivot) {
      this.$http
          .post(this.$store.getters.appUrl + "v2/update-exam-order", pivot)
          .then(() => {
            this.$toasted.success(this.$store.getters.translate("success"));
          })
          .catch((error) => {
            this.$toasted.error(error);
          });
    },
    exportPerson(export_type) {
      this.loading = true;
      if (this.user_type === 'candidate' && this.record.dossiers.length > 0) {
        this.dossier_counter = 0;
        this.exams_for_export = [];
        this.record_results = [];
        this.loadDossiers(export_type, this.record.dossiers[0].id);
      }
      else {
        let html = helpFunctions.exportPerson(export_type, this.user_type, this.record, this.usual_fields, this.custom_fields);
        if (export_type === 'excel') {
          this.exportTableToExcel(html, this.record.name);
        } else {
          this.exportTableToPdf(html, this.record.name);
        }
      }
    },
    loadDossiers(export_type, dossier_id) {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/dossiers/" + dossier_id)
          .then((response) => {
            let dossier = response.data;
            if (dossier.custom_fields == null || Array.isArray(dossier.custom_fields) || typeof dossier.custom_fields != "object") {
              dossier.custom_fields = {};
            }
            if (dossier.exams && dossier.exams.length > 0) {
              dossier.exams = helpFunctions.getLocationsAndRooms(dossier.exams, true, true);
              for (let i = 0; i < dossier.exams.length; i++) {
                var temp = {
                  id: dossier.id,
                  scheme: dossier.scheme,
                  scheme_id: dossier.scheme_id,
                  candidate_id: dossier.candidate_id,
                  results: []
                };
                for (let x = 0; x < dossier.results.length; x++) {
                  if(dossier.results[x].session != null && dossier.results[x].session.exam_id === dossier.exams[i].id) {
                    temp.results.push(dossier.results[x]);
                  }
                }
                this.exams_for_export.push(dossier.exams[i]);
                this.record_results.push(temp);
              }
            }
            this.dossier_counter++;
            if(this.dossier_counter < this.record.dossiers.length) {
              this.loadDossiers(export_type, this.record.dossiers[this.dossier_counter].id);
            }
            else {
              this.record.exams_for_export = this.exams_for_export;
              this.record.record_results = this.record_results;
              let html = helpFunctions.exportPerson(export_type, this.user_type, this.record, this.usual_fields, this.custom_fields);
              if (export_type === 'excel') {
                this.exportTableToExcel(html, this.record.name);
              } else {
                this.exportTableToPdf(html, this.record.name);
              }
            }
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    exportTableToPdf(html, file_name) {
      this.$http
          .post(this.$store.getters.appUrl + "v2/pdf/generate-pdf", {html: html, file_name: file_name}, {responseType: "blob"})
          .then((response) => {
            const blob = new Blob([response.data], {type: "application/pdf"});
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = file_name + ".pdf";
            link.click();
            URL.revokeObjectURL(link.href);
            this.loading = false;
          });
    },
    exportTableToExcel(html, file_name) {
      const link = document.createElement("a");
      link.href = 'data: application/vnd.ms-excel, ' + html.replace(/ /g, '%20');
      link.download = file_name + ".xls";
      link.click();
      this.loading = false;
    },
    usualFieldDataChanged(data) {
      if(data[0] === 'can_view_certificates' || data[0] === 'jobtitle' || data[0] === 'reference' || data[0] === 'is_dyslexic' ||
          data[0] === 'is_dyscalc' || data[0] === 'preferred_certificate_language' || data[0] === 'type') {
        if(this.module_name === 'portal' || this.module_name === 'profile') {
          this.record.person[this.user_type][data[0]] = data[1];
        }
        else {
          this.record[data[0]] = data[1];
        }
      }
      else {
        this.record.person[data[0]] = data[1];
      }
    },
    customFieldDataChanged(data) {
      if(this.module_name === 'portal' || this.module_name === 'profile') {
        this.record.person[this.user_type].custom_fields[data[0]] = data[1];
      }
      else {
        this.record.custom_fields[data[0]] = data[1];
      }
    },
    async getFields() {
      var condition = '&show_on_update=true';
      if (this.module_name === "portal") {
        condition = '&show_in_portal=true';
      }
      this.$http
          .get(this.$store.getters.appUrl + "v2/fields?source=site" + condition + "&model_type=" + this.user_type)
          .then((response) => {
            this.custom_fields = [];
            this.usual_fields = [];
            response.data.data.forEach((field) => {
              if (field.custom_field == 1) {
                this.custom_fields.push(field);
              }
              else if(field.name === 'tags') {
                this.tags_required = true;
              }
              else if(field.name === 'name') {
                this.name_required = true;
              }
              else {
                if(field.name === 'country_of_birth_id') {
                  field.available_options = this.countries;
                }
                else if(field.name === 'nationality_id') {
                  field.available_options = this.nationalities;
                }
                else if(field.field_type === 'select_single' || field.field_type === 'select_multiple') {
                  for (let i = 0; i < field.available_options.length; i++) {
                    field.available_options[i] = {
                      text: this.$store.getters.translate(field.available_options[i]),
                      value: field.available_options[i],
                    };
                  }
                }
                this.usual_fields.push(field);
              }
            });
            this.refreshAddresses();
            this.loading = false;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    getCountryNationality() {
      this.$http
        .get(this.$store.getters.appUrl + "v2/countries")
        .then((response) => {
          this.countries = [];
          response.data.country.forEach((option) => {
            this.countries.push({
              value: option.country_id,
              text: option.country_name,
            });
          });
          this.nationalities = [];
          response.data.nationality.forEach((option) => {
            this.nationalities.push({
              value: option.nationality_id,
              text: option.nationality_name,
            });
          });
          this.getFields();
        })
        .catch((error) => {
          this.$toasted.error(error);
        });
    },
    refreshAddresses() {
      if(this.record && this.record.person) {
        if (this.user_type === 'candidate') {
          this.record.person.addresses.forEach((address) => {
            if (address.primary) {
              this.hide_billing_address = true;
            }
          });
        }
        else if (this.user_type === 'contact') {
          this.record.companies.forEach((company, index) => {
            this.hide_company_billing_address[index] = false;
            company.addresses.forEach((address) => {
              if (address.primary) {
                this.hide_company_billing_address[index] = true;
              }
            });
          });
        }
      }
    },
    cleanCache() {
      this.$emit('clean_cache');
    },
    canEdit() {
      if (this.profile) {
        return true;
      }
      return this.$can("edit", this.user_type);
    },
    canDelete() {
      if (this.profile) {
        return false;
      }
      return this.$can("delete", this.user_type);
    },
    hideCompanyBillingAddress(index) {
      if(this.user_type === 'contact') {
        if (this.hide_company_billing_address[index]) {
          this.record.companies[index].addresses.forEach((address) => {
            if (address.type === 'billing') {
              this.$http
                  .delete(this.$store.getters.appUrl + "v2/addresses/" + address.id)
                  .then(() => {
                    this.record.companies[index].addresses = this.record.companies[index].addresses.filter((address2) => address2.id !== address.id);
                  })
                  .catch((error) => {
                    this.$toasted.error(error);
                    this.loading = false;
                  });
            } else if (address.type === 'visiting' && !address.primary) {
              address.primary = 1;
              this.$http
                  .patch(this.$store.getters.appUrl + "v2/addresses/" + address.id, address)
                  .then(() => {
                    this.$toasted.success(this.$store.getters.translate("success"));
                  })
                  .catch((error) => {
                    this.$toasted.error(error);
                    this.loading = false;
                  });
            }
          });
        } else {
          this.record.companies[index].addresses.forEach((address) => {
            if (address.type === 'visiting' && address.primary) {
              address.primary = 0;
              this.$http.patch(this.$store.getters.appUrl + "v2/addresses/" + address.id, address);
            }
          });
        }
      }
    }
  },
  watch: {
    record: {
      handler() {
        if (this.module_name !== 'portal' && this.module_name !== 'profile' && this.user_type === 'candidate' && this.record.dossiers && this.record.dossiers.length > 0) {
          this.last_dossier_id = this.record.dossiers[this.record.dossiers.length-1].id;
        }
        this.refreshAddresses();
      },
    },
    user_type: {
      handler() {
        this.getFields();
      },
    },
    hide_billing_address: {
      handler() {
        if(this.user_type === 'candidate') {
          if (this.hide_billing_address) {
            this.record.person.addresses.forEach((address) => {
              if (address.type === 'billing') {
                this.$http
                    .delete(this.$store.getters.appUrl + "v2/addresses/" + address.id)
                    .then(() => {
                      this.record.person.addresses = this.record.person.addresses.filter((address2) => address2.id !== address.id);
                    })
                    .catch((error) => {
                      this.$toasted.error(error);
                      this.loading = false;
                    });
              } else if (address.type === 'visiting' && !address.primary) {
                address.primary = 1;
                this.$http
                    .patch(this.$store.getters.appUrl + "v2/addresses/" + address.id, address)
                    .then(() => {
                      this.$toasted.success(this.$store.getters.translate("success"));
                    })
                    .catch((error) => {
                      this.$toasted.error(error);
                      this.loading = false;
                    });
              }
            });
          } else {
            this.record.person.addresses.forEach((address) => {
              if (address.type === 'visiting' && address.primary) {
                address.primary = 0;
                this.$http.patch(this.$store.getters.appUrl + "v2/addresses/" + address.id, address);
              }
            });
          }
        }
      },
    },
  },
};
</script>
<style scoped>
.centered-input >>> input {
  text-align: center;
}
.centered-input {
  position: relative;
  top: 7px;
}
.label-custom {
  border: 0px !important;
  font-style: italic;
}
</style>