<template>
  <div>
    <template>
      <textarea
        :style="'height:' + getHeight"
        ref="textarea"
        id="log_textarea"
        v-model="new_value"
        @change="save"
        :placeholder="$store.getters.translate('log')"
        @click="setFocus"/>
      <v-text-field
        :placeholder="$store.getters.translate('add_to_log')"
        single-line
        dense
        v-model="add_line"
        @keyup.enter="insertContent"
        class="custom-text-field"
        hide-details
        ref="add_line">
        <v-icon @click="insertContent" slot="append" color="primary">mdi-plus</v-icon>
      </v-text-field>
    </template>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: ["model_type", "model_id", "related_model_type", "related_model_id", "height"],
  data() {
    return {
      new_value: null,
      model_log: null,
      related_model_log: null,
      add_line: null,
      set_first_focus: true,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      if(this.model_id) {
        this.$http
            .get(this.$store.getters.appUrl + "v2/model-logs?model_type=" + this.model_type + "&model_id=" + this.model_id)
            .then((response) => {
              this.model_log = response.data;
              this.new_value = this.model_log;
              this.scroll();
              if(this.related_model_id) {
                this.$http
                    .get(this.$store.getters.appUrl + "v2/model-logs?model_type=" + this.related_model_type + "&model_id=" + this.related_model_id)
                    .then((response) => {
                      this.related_model_log = response.data;
                      if(this.related_model_log !== "") {
                        if(this.model_log !== "") {
                          this.new_value = this.model_log + "\n\n" + this.related_model_type + " log" + "\n\n" + this.related_model_log;
                        }
                        else {
                          this.new_value = this.related_model_type + " log" + "\n\n" + this.related_model_log;
                        }
                      }
                    })
                    .catch(function (error) {
                      if (this.$store.getters.isLoggedIn) {
                        this.$toasted.error(error);
                      }
                      this.loading = false;
                    });
              }
            })
            .catch(function (error) {
              if (this.$store.getters.isLoggedIn) {
                this.$toasted.error(error);
              }
              this.loading = false;
            });
      }
    },
    save() {
      this.$http
        .post(this.$store.getters.appUrl + "v2/model-logs", {
          model_type: this.model_type,
          model_id: this.model_id,
          log: this.model_log,
        })
        .then(() => {
          this.load();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    setFocus() {
      if (this.set_first_focus) {
        this.$refs.add_line.focus();
        this.set_first_focus = false;
      }
    },
    scroll() {
      this.$nextTick(() => {
        var textarea = document.getElementById("log_textarea");
        textarea.scrollTop = textarea.scrollHeight;
      });
    },
    insertContent() {
      if (this.add_line) {
        let line = "[" + moment().format("DD-MM-YYYY HH:mm") + " - " + this.$store.getters.getUser.person.casual_name + "] " + this.add_line;
        if (!this.model_log) {
          this.model_log = line;
        } else {
          this.model_log = this.model_log + "\n" + line;
        }
        this.new_value = this.model_log;
        if(this.related_model_log != null && this.related_model_log !== "") {
          this.new_value = this.model_log + "\n\n" + this.related_model_type + " log" + "\n\n" + this.related_model_log;
        }
        this.add_line = null;
        this.save();
      }
    },
  },
  computed: {
    getHeight() {
      if (this.height) {
        return this.height;
      }
      return "150px";
    },
  },
  watch: {
    model_id: {
      handler() {
        this.load();
      },
    },
    related_model_id: {
      handler() {
        this.load();
      },
    },
  },
};
</script>

<style scoped>
textarea {
  width: 100%;
  font-size: 12px !important;
  border-top: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 5px;
  margin-bottom: 0px !important;
  resize: none;
}

textarea:focus {
  outline: none !important;
}

input {
  padding: 0 !important;
}

.custom-text-field {
  padding: 5px;
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-radius: 5px;
  width: 100%;
  font-size: 12px !important;
  margin-top: -10px !important;
  margin-bottom: 15px;
}
</style>