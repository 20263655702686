<template>
  <v-data-iterator
    v-if="dossier.scheme"
    :items="dossier.scheme.scheme_sessions"
    hide-default-footer>
    <template v-slot:default="props">
      <v-row>
        <v-col v-for="(scheme_session, index) in props.items" :key="index">
          <v-card dark color="secondary" class="mb-3">
            <v-toolbar dark color="primary" class="elevation-1" dense flat>
              <v-toolbar-title>
                <small>{{ scheme_session.name }} ({{ scheme_session.cesuur }})</small>
              </v-toolbar-title>
              <v-spacer/>
              <v-btn text small @click="addSession(scheme_session)">
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </v-toolbar>
          </v-card>
          <template v-for="(exam_candidate_session, index) in dossier.results">
            <v-card
              class="mb-3"
              v-if="exam_candidate_session.scheme_session_id === scheme_session.id"
              :key="index"
              :disabled="loading || exam_candidate_session.expired">
              <v-toolbar dense flat>
                <v-chip
                  :color="exam_candidate_session.status === 'passed' ? 'success' : null"
                  label
                  small
                  v-if="exam_candidate_session.status && !exam_candidate_session.expired">
                  {{ $store.getters.translate(exam_candidate_session.status) }}
                </v-chip>
                <v-chip
                  label
                  color="error"
                  small
                  v-else-if="exam_candidate_session.expired">
                  {{ $store.getters.translate("expired") }}
                </v-chip>
                <v-spacer/>
                <template v-if="$store.getters.getUserType !== 'candidate'">
                  <v-menu close-on-click>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn small text v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <base-link
                        v-if="exam_candidate_session.status !== 'exempt' && exam_candidate_session.status !== 'absent' && !exam_candidate_session.result"
                        :list="true"
                        @change="addToExam"
                        module_name="exams"
                        :scheme_id="dossier.scheme_id"
                        @click="active_exam_candidate_session = exam_candidate_session"
                        :single_select="true"
                        :label="$store.getters.translate('add_to_exam')"/>
                      <v-list-item
                        v-if="exam_candidate_session.status !== 'exempt' && exam_candidate_session.status !== 'absent' && !exam_candidate_session.result"
                        @click="setStatus(exam_candidate_session, 'absent')">
                        <v-item-list-icon>
                          <v-icon small class="mr-2">mdi-exit-to-app</v-icon>
                        </v-item-list-icon>
                        <v-list-item-title>
                          {{ $store.getters.translate("absent") }}
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        v-if="exam_candidate_session.status !== 'exempt' && exam_candidate_session.status !== 'absent' && !exam_candidate_session.result"
                        @click="setStatus(exam_candidate_session, 'exempt')">
                        <v-item-list-icon>
                          <v-icon class="mr-2" small>mdi-bookmark-check</v-icon>
                        </v-item-list-icon>
                        <v-list-item-title>
                          {{ $store.getters.translate("exempt") }}
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="deleteStatus(exam_candidate_session)">
                        <v-item-list-icon>
                          <v-icon small class="mr-2">mdi-undo</v-icon>
                        </v-item-list-icon>
                        <v-list-item-title>
                          {{ $store.getters.translate("reset") }}
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="deleteSession(exam_candidate_session)">
                        <v-item-list-icon>
                          <v-icon small class="mr-2">mdi-trash-can</v-icon>
                        </v-item-list-icon>
                        <v-list-item-title>
                          {{ $store.getters.translate("delete") }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-toolbar>
              <v-divider/>
              <v-list
                v-if="exam_candidate_session.session"
                :disabled="exam_candidate_session.status === 'exempt' || exam_candidate_session.status === 'absent'"
                dense>
                <template>
                  <v-list-item color="error">
                    <v-list-item-content>
                      <small>{{ $store.getters.translate("exam_id") }}:</small>
                    </v-list-item-content>
                    <v-list-item-content class="text-end">
                      <small v-if="$store.getters.getUserType !== 'candidate'">
                        <a @click="$router.push('/exams/' + exam_candidate_session.session.exam_id)">
                          {{ exam_candidate_session.session.exam_id }}
                        </a>
                      </small>
                      <small v-else>
                        {{ exam_candidate_session.session.exam_id }}
                      </small>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <small>{{ $store.getters.translate('result') }}:</small>
                    </v-list-item-content>
                    <v-list-item-content class="justify-end">
                      <div class="text-end" v-if="$store.getters.getUserType !== 'candidate'" style="max-width: 50px">
                        <v-text-field
                          :disabled="exam_candidate_session.status === 'absent' || exam_candidate_session.status === 'exempt' || dossier.agreed_with_terms_on == null"
                          flat
                          v-model="exam_candidate_session.result"
                          dense
                          class="centered-input align-right"
                          style="border-radius: 3px"
                          placeholder="-"
                          single-line
                          @change="updateParticipant(exam_candidate_session)"
                          :background-color="backgroundColor(scheme_session.cesuur, exam_candidate_session)"
                          dark
                          :tabindex="exam_candidate_session.name"/>
                      </div>
                      <small v-else>
                        {{ exam_candidate_session.result }}
                      </small>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="exam_candidate_session.session.exam">
                    <v-list-item-content>
                      <small>{{ $store.getters.translate("examination_date") }}:</small>
                    </v-list-item-content>
                    <v-list-item-content class="text-end">
                      <small v-if="exam_candidate_session.session.exam.examination_date">
                        {{ exam_candidate_session.session.exam.examination_date }}
                      </small>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <small>{{ $store.getters.translate("exam_version") }}:</small>
                    </v-list-item-content>
                    <v-list-item-content class="text-end">
                      <small>{{ exam_candidate_session.exam_version }}</small>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>
            </v-card>
          </template>
        </v-col>
      </v-row>
    </template>
  </v-data-iterator>
</template>

<script>
import BaseLink from "../../../components/commonComponents/BaseLink";

export default {
  props: ["dossier"],
  components: {
    BaseLink,
  },
  data() {
    return {
      active_exam_candidate_session: null,
      loading: false,
    };
  },
  methods: {
    addSession(scheme_session) {
      if(!this.loading) {
        this.loading = true;
        let session_id = null;
        this.dossier.exam.sessions.forEach((session) => {
          if (scheme_session.id === session.scheme_session_id) {
            session_id = session.id;
          }
        });
        if (session_id) {
          let request = {
            candidate_id: this.dossier.candidate_id,
            session_id: session_id,
            dossier_id: this.dossier.id,
            scheme_session_id: scheme_session.id,
            new_status: "pending",
          };
          this.addCandidateToSession(request);
        } else {
          let session_request = {
            location_id: this.dossier.exam.location_id,
            exam_id: this.dossier.exam.id,
            scheme_session_id: scheme_session.id,
            warrant_id: scheme_session.warrant_id,
            name: scheme_session.name,
            cesuur: scheme_session.cesuur,
            price: scheme_session.price,
            room_id: this.dossier.exam.room_id,
            max_candidates: scheme_session.max_candidates,
          };
          this.$http
              .post(this.$store.getters.appUrl + "v2/exams/" + this.dossier.exam.id + "/sessions", session_request)
              .then((response) => {
                let request = {
                  candidate_id: this.dossier.candidate_id,
                  session_id: response.data.id,
                  dossier_id: this.dossier.id,
                  scheme_session_id: scheme_session.id,
                  new_status: "pending",
                };
                this.addCandidateToSession(request);
              })
              .catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toasted.error(error);
                }
                this.loading = false;
              });
        }
      }
    },
    addCandidateToSession(request) {
      this.$http
          .post(this.$store.getters.appUrl + "v2/examcandidatesessions", request)
          .then(() => {
            this.$emit("refresh");
            this.loading = false;
            this.$toasted.success(this.$store.getters.translate("success"));
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    updateParticipant(item) {
      this.$http
        .patch(this.$store.getters.appUrl + "v2/examcandidatesessions/" + item.id, item)
        .then((response) => {
          item.status = response.data.status;
          this.$emit("refresh");
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
        });
      return item;
    },
    deleteSession(item) {
      this.loading = true;
      this.$http
        .delete(this.$store.getters.appUrl + "v2/examcandidatesessions/" + item.id)
        .then(() => {
          this.loading = false;
          this.$emit("refresh");
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
        });
      return item;
    },
    setStatus(item, status) {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then((res) => {
          if (res) {
            item.new_status = {
              name: status,
            };
            item.result = null;
            this.$http
              .patch(this.$store.getters.appUrl + "v2/examcandidatesessions/" + item.id, item)
              .then((response) => {
                item.status = response.data.status;
                this.$emit("refresh");
              })
              .catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toasted.error(error);
                }
                this.loading = false;
              });
          }
        }
      );
    },
    deleteStatus(item) {
      this.$confirm(this.$store.getters.translate("are_you_sure")).then((res) => {
          if (res) {
            item.remove_status = true;
            this.$http
              .patch(this.$store.getters.appUrl + "v2/examcandidatesessions/" + item.id, item)
              .then((response) => {
                item.status = response.data.status;
                this.$emit("refresh");
              })
              .catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toasted.error(error);
                }
                this.loading = false;
              });
          }
        }
      );
    },
    backgroundColor(cesuur, item) {
      if (item.status === "exempt" || item.status === "absent" || parseInt(cesuur) === 0) {
        return "grey";
      }
      if (parseInt(cesuur) > parseInt(item.result) || !item.result) {
        return "#ff7f75";
      }
      return "#56d65b";
    },
    addToExam(objects) {
      this.loading = true;
      if (this.dossier.scheme_id === objects[0].scheme_id) {
        this.$http
          .get(this.$store.getters.appUrl + "v2/exams/" + objects.id + "/sessions")
          .then((response) => {
            response.data.forEach((session) => {
              if (this.active_exam_candidate_session.scheme_session_id === session.scheme_session_id) {
                this.active_exam_candidate_session.session_id = session.id;
                this.$http
                  .patch(this.$store.getters.appUrl + "v2/examcandidatesessions/" + this.active_exam_candidate_session.id, this.active_exam_candidate_session)
                  .then(() => {
                    this.loading = false;
                    this.$toasted.success(this.$store.getters.translate("success"));
                    this.$emit("refresh");
                  })
                  .catch((error) => {
                    if (this.$store.getters.isLoggedIn) {
                      this.$toasted.error(error);
                    }
                    this.loading = false;
                  });
              }
              this.loading = false;
            });
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
      } else {
        this.loading = false;
        this.$toasted.info("this_exam_has_a_different_scheme");
      }
    },
  },
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
  width: 50px;
}
.centered-input {
  position: relative;
  top: 7px;
}

.sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 30px;
}
</style>